import cn from 'classnames';
import { Link } from 'gatsby';
import { SyntheticEvent, useMemo } from 'react';
import Arrow from 'src/components/common/Arrow';
import * as styles from './styles/Pagination.module.scss';

interface Props {
  total: number;
  current: number;
  prefixPath?: string;
}

export default function Pagination({ total, current, prefixPath = '' }: Props) {
  const pages = useMemo(() => getPages({ total, current }), [total, current]);

  const validatePageLink = (e: SyntheticEvent, to: number) => {
    if (to < 1 || to > total) {
      e.preventDefault();
    }
  };

  return (
    <div className={styles.container}>
      <Link
        className={cn(styles.link, styles.page, { [styles.disabled]: current === 1 })}
        to={current === 2 ? `${prefixPath}/` : `${prefixPath}/${current - 1}`}
        onClick={e => validatePageLink(e, current - 1)}
      >
        <Arrow direction="left" />
      </Link>
      {pages.map(page => (
        <Page page={page} selected={current === page} key={page} prefixPath={prefixPath} />
      ))}
      <Link
        className={cn(styles.link, styles.page, { [styles.disabled]: current === total })}
        to={`${prefixPath}/${current + 1}`}
        onClick={e => validatePageLink(e, current + 1)}
      >
        <Arrow />
      </Link>
    </div>
  );
}

function Page({ page, selected, prefixPath }: { page: number; selected: boolean; prefixPath?: string }) {
  return page >= 0 ? (
    <Link
      key={page}
      to={page === 1 ? `${prefixPath}/` : `${prefixPath}/${page}`}
      className={cn(styles.link, styles.page, { [styles.selected]: selected })}
    >
      {page}
    </Link>
  ) : (
    <span className={styles.page}>...</span>
  );
}

const PAGE_SIZE = 5;
const getPages = ({ total: totalPage, current }: { total: number; current: number }) => {
  const pages: Array<number> = [current];

  const middle = Math.ceil(PAGE_SIZE / 2);

  let page = current;
  const total = Math.max(totalPage, current);
  const nextLength = Math.min(middle, total - current);
  const prevLength = Math.max(middle, PAGE_SIZE + 1 - nextLength);
  while (page > 1) {
    // 전의 페이지가 2개 이상 남은 경우 ...을 표시
    if (page > 3 && pages.length === prevLength - 1) {
      pages.unshift(1, -1);
      break;
    }
    pages.unshift(--page);
  }

  page = current;
  while (page < total) {
    // 후의 페이지가 2개 이상 남은 경우 ...을 표시
    if (pages.length === PAGE_SIZE && page < total - 2) {
      pages.push(-1, total);
      break;
    }
    pages.push(++page);
  }

  return pages;
};
