import cn from 'classnames';
import { PropsWithChildren } from 'react';

import * as styles from './styles/Pill.module.scss';

interface Props {
  selected?: boolean;
  sizeVariant?: 'small' | 'medium';
}

export default function Pill({ selected, sizeVariant = 'medium', children }: PropsWithChildren<Props>) {
  return <span className={cn(styles.pill, styles[sizeVariant], { [styles.selected]: selected })}>{children}</span>;
}
