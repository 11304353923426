import cn from 'classnames';
import { Link } from 'gatsby';
import { useContext } from 'react';
import Arrow from 'src/components/common/Arrow';
import Pill from 'src/components/common/Pill';
import { UIContext } from 'src/state/UIContext';
import * as styles from './styles/KeywordList.module.scss';

interface Props {
  keywords: [string, number][];
  current?: string;
}

export default function KeywordList({ current, keywords }: Props) {
  const { keywordOpened: opened, toggleKeywordOpened: toggleOpened } = useContext(UIContext);

  return (
    <div className={styles.container}>
      <div className={styles.title}>KEYWORDS</div>
      <div className={cn(styles.keywords, { [styles.opened]: opened })}>
        <Link to="/">
          <Pill selected={!current}>ALL</Pill>
        </Link>
        {/* 2번째 인자로 아티클 갯수를 받을 수 있으나 현재 키워드 당 아티클 갯수가 너무 적어서 노출 안함 */}
        {keywords.map(([keyword]) => (
          <Link key={keyword} to={`/keywords/${keyword}`}>
            <Pill selected={current === keyword}>{keyword}</Pill>
          </Link>
        ))}
      </div>
      <button className={cn(styles.fold)} onClick={toggleOpened}>
        {opened ? '접기' : '펼치기'}
        <Arrow direction={opened ? 'top' : 'bottom'} />
      </button>
    </div>
  );
}
