import KeywordList from 'src/components/home/KeywordList';
import Pagination from 'src/components/home/Pagination';
import ThumbnailContainer from 'src/components/home/ThumbnailContainer';
import ThumbnailItem from 'src/components/home/ThumbnailItem';
import { useIntersectionObserver } from 'src/hooks/useIntersectionObserver';

import * as styles from './styles/PostList.module.scss';

interface Props {
  totalPage: number;
  currentPage: number;
  posts: { node: Post }[];
  prefixPath?: string;
  keywords: [string, number][];
  currentKeyword?: string;
}

export default function PostList({ posts, totalPage, currentPage, prefixPath, keywords, currentKeyword }: Props) {
  useIntersectionObserver();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.postList}>
          <ThumbnailContainer>
            {posts.map(({ node }) => (
              <ThumbnailItem node={node} key={`item_${node.fields.slug}`} />
            ))}
          </ThumbnailContainer>
        </div>
        <div className={styles.keywordList}>
          <KeywordList keywords={keywords} current={currentKeyword} />
        </div>
      </div>
      <Pagination total={totalPage} current={currentPage} prefixPath={prefixPath} />
    </div>
  );
}
