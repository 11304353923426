import { graphql, PageProps } from 'gatsby';
import Layout from 'src/components/common/Layout';

import Meta from 'src/components/common/Meta';
import PostList from 'src/components/home/PostList';
import { HOST_NAME } from 'src/constant';
import useSiteMetadata from 'src/hooks/useSiteMetadata';

interface Data {
  allMarkdownRemark: {
    edges: { node: Post }[];
  };
}

interface Context {
  totalPage: number;
  currentPage: number;
  keywords: [string, number][];
}

export default function BlogList({ data, pageContext }: PageProps<Data, Context>) {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout visibleTopImage>
      <PostList
        posts={posts}
        totalPage={pageContext.totalPage}
        currentPage={pageContext.currentPage}
        keywords={pageContext.keywords}
      />
    </Layout>
  );
}

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          excerpt
          fields {
            slug
            thumbnailImageDefault {
              childImageSharp {
                gatsbyImageData
              }
            }
            readingTime
          }
          frontmatter {
            author
            title
            date(formatString: "YYYY.MM.DD")
            description
            keywords
            thumbnailImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data, pageContext }: PageProps<Data, Context>) => {
  const sitemeta = useSiteMetadata();
  const posts = data.allMarkdownRemark.edges;

  return (
    <>
      <Meta title="홈" />
      <link rel="canonical" href={sitemeta.siteUrl} />
      {pageContext.currentPage === 1 && (
        <script type="application/ld+json">
          {`
          {
            "@context":"https://schema.org",
            "@type":"ItemList",
            "itemListElement": ${JSON.stringify(
              posts.map((post, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                url: `${HOST_NAME}${post.node.fields.slug}`,
              }))
            )}
          }
        `}
        </script>
      )}
    </>
  );
};
