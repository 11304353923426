import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Divider from 'src/components/common/Divider';
import Pill from 'src/components/common/Pill';
import PostDate from 'src/components/post/PostDate';
import PostReadingTime from 'src/components/post/PostReadingTime';
import { useAuthor } from 'src/hooks/useAuthor';
import { TARGET_CLASS } from 'src/utils/visible';

import * as styles from './styles/ThumbnailItem.module.scss';

interface Props {
  node: Post;
}

export default function ThumbnailItem({ node }: Props) {
  const { name, thumbnail } = useAuthor(node.frontmatter.author);
  const {
    frontmatter: { thumbnailImage },
    fields: { thumbnailImageDefault },
  } = node;

  const image =
    getImage(thumbnailImage?.childImageSharp?.gatsbyImageData ?? null) ??
    getImage(thumbnailImageDefault?.childImageSharp?.gatsbyImageData ?? null);

  return (
    <Link className={`${styles.wrapper} ${TARGET_CLASS}`} to={node.fields.slug ?? ''}>
      <div key={node.fields.slug} className={styles.content}>
        <h6 className={styles.author}>
          <img src={thumbnail} />
          <span>{name}</span>
        </h6>
        <h3 role="heading" className={styles.title}>
          {node.frontmatter.title ?? node.fields.slug}
        </h3>
        <div className={styles.descriptionWrapper}>
          {image && (
            <GatsbyImage className={styles.thumbnailImage} image={image} alt={`thumbnail-image-${node.fields.slug}`} />
          )}
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: node.frontmatter.description ?? node.excerpt ?? '' }}
          />
        </div>
      </div>
      <div className={styles.info}>
        <PostDate date={node.frontmatter.date} />
        <Divider />
        <PostReadingTime minutes={node.fields.readingTime ?? 0} />
        {!!node.frontmatter.keywords && (
          <>
            <Divider />
            <div className={styles.keywords}>
              {node.frontmatter.keywords.map(keyword => (
                <Link key={keyword} to={`/keywords/${keyword}`}>
                  <Pill sizeVariant="small">{keyword}</Pill>
                </Link>
              ))}
            </div>
          </>
        )}
      </div>
    </Link>
  );
}
